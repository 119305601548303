import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb  from "../components/breadcrumb"
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

export default function CipeHouTo(props) {
  const { siteTitle } = useSiteMetadata()
  const title="Requisitos para solicitar CIPE | "+ siteTitle
  return (
    <Layout >
         <Helmet title={title}>
          <meta charSet="utf-8" />
        
        </Helmet>
      <Container>
        <h1 className="text-center m-4">Requisitos para solicitar CIPE</h1>
        <Row className="justify-content-center">
          <Col sm="12" md={{ size: 8 }}>
        
            <h4> EN TODOS LOS CASOS, EL TRÁMITE DE SOLICITUD DE CIPE ES DE CARÁCTER PERSONAL E INDELEGABLE</h4>
            <h3>REQUISITOS GENERALES PARA LA EMISIÓN CIPE: </h3>
            <ul>
              <li>
                Mayores de 85 años: LE (libreta de enrolamiento); LC (libreta cívica). http://www.nuevodni.gov.ar/inicio/
              </li>
              <li>
              Documento Nacional de Identidad (DNI), con domicilio actualizado.
              </li>
           </ul>
            <h3> MENORES DE EDAD:</h3>
            <ul>
              <li>DNI del menor en el que figuren los datos de los tutores al dorso, en el caso que no figuren deberá presentar la partida de nacimiento.</li>
              <li>Asistir en compañía de su madre, padre o tutor.</li>
              <li>DNI de la madre, padre o tutor que lo acompañe.</li>
              <li>En caso de Tutor: Presentar Resolución Judicial que acredite tal carácter.</li>
              <li>Requisitos Generales.</li>
            </ul>

          </Col>
        </Row>
      </Container>
     
      <Breadcrumb pageContext={props.pageContext} label="¿Cómo obtengo mi cipe?"/>

    </Layout>
  )
}
